import { createFeature, createReducer, on } from '@ngrx/store';
import { ImporterImportActions } from './importer-import.actions';
import { ImporterImport } from 'src/app/shared/models';

export const importerImportFeatureKey = 'importer-import';

export interface ImporterImportState {
  importerImports: ImporterImport[];
  importerImport: ImporterImport | null;
  loading: boolean;
  error: any;
}

export const initialState: ImporterImportState = {
  importerImports: [],
  importerImport: null,
  loading: false,
  error: null,
};

export const reducer = createReducer(
  initialState,
  on(
    ImporterImportActions.loadImporterImports,
    (state): ImporterImportState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterImportActions.loadImporterImportsSuccess,
    (state, { importerImports }): ImporterImportState => ({
      ...state,
      importerImports: [
        ...state.importerImports.filter(
          x =>
            !importerImports.find(
              y => x.userId && x.userId === y.userId && x.id === y.id
            )
        ),
        ...importerImports,
      ],
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterImportActions.loadImporterImportsFailure,
    (state, { error }): ImporterImportState => ({
      ...state,
      loading: false,
      error,
    })
  ),
  on(
    ImporterImportActions.loadImporterImport,
    (state): ImporterImportState => ({
      ...state,
      loading: true,
      error: null,
    })
  ),
  on(
    ImporterImportActions.loadImporterImportSuccess,
    (state, { importerImport }): ImporterImportState => ({
      ...state,
      importerImports: [
        ...state.importerImports.filter(item => importerImport.id !== item.id),
        importerImport,
      ],
      importerImport: importerImport,
      loading: false,
      error: null,
    })
  ),
  on(
    ImporterImportActions.loadImporterImportFailure,
    (state, { error }): ImporterImportState => ({
      ...state,
      loading: false,
      error,
    })
  )
);

export const importerImportFeature = createFeature({
  name: importerImportFeatureKey,
  reducer,
});
