import { createActionGroup, props } from '@ngrx/store';
import { ImporterImport, ImporterImport_Status } from 'src/app/shared/models';

export const ImporterImportActions = createActionGroup({
  source: 'ImporterImport',
  events: {
    'Load ImporterImports': props<{
      userId: string;
      importerChannelId: string;
      from?: any;
      size?: any;
      term?: string;
      status?: ImporterImport_Status;
    }>(),
    'Load ImporterImports Success': props<{
      importerImports: ImporterImport[];
      userId?: string;
      importerChannelId?: string;
    }>(),
    'Load ImporterImports Failure': props<{ error: any }>(),
    'Load ImporterImport': props<{
      importerImportId: string;
      userId: string;
      importerChannelId?: string;
    }>(),
    'Load ImporterImport Success': props<{ importerImport: ImporterImport }>(),
    'Load ImporterImport Failure': props<{ error: any }>(),
  },
});
